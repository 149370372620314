import axios from "axios";
import ListenEvent from "@/libs/ListenEvent.js";
import ElementUI, { Loading } from "element-ui";

class HttpRequest {
  constructor(baseUrl, options = {}) {
    this.baseUrl = baseUrl;
    this.queue = {};
    this.newOptions = options;
  }
  // 配置
  getInsideConfig() {
    const config = {
      baseURL: this.baseUrl,
      headers: {
        // 'Content-Type': 'application/json;charset=UTF-8'
      },
    };
    return config;
  }
  destroy(url) {
    delete this.queue[url];
    if (!Object.keys(this.queue).length) {
      // Spin.hide()
    }
  }
  interceptors(instance, options) {
    const url = options.url;
    // 请求拦截，所有请求开始前先到此处
    instance.interceptors.request.use(
      (config) => {
        // 请求头带token
        if (window.sessionStorage.getItem("token")) {
          config.headers.Authorization = window.sessionStorage.getItem("token");
        }

        // 添加全局的loading...
        if (!Object.keys(this.queue).length) {
          // Spin.show() // 不建议开启，因为界面不友好
        }
        this.queue[url] = true;
        // 增加对请求的参数进行空字符串传undefined的处理
        /* if (config.data && config.data instanceof Object) {
          for (let key in config.data) {
            if (config.data[key] === "") {
              config.data[key] = undefined;
            }
          }
        } */
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    // 响应拦截，所有请求的相应先到此处,对响应数据操作
    instance.interceptors.response.use(
      async (res) => {
        this.destroy(url);
        const { data, status } = res;
        // 新增的接口走这里处理
        if (this.newOptions.isNew) {
          if (status === 200 && data.status === 200) {
            return data.data;
          }
          if (data.status !== 200) {
            // ElementUI.Message.error(data.message);
            return Promise.reject(res);
          }
        }
        if (res.data.status === 1002) {
          console.log("-1002", res);
          return ListenEvent.$emit("goLogin");
        }

        return { data, status };
      },
      (error) => {
        this.destroy(url);

        let errorInfo = error.response;
        // 为空时
        if (!errorInfo) {
          const {
            request: { statusText, status },
            config,
          } = JSON.parse(JSON.stringify(error));
          errorInfo = {
            statusText,
            status,
            request: { responseURL: config.url },
          };
        }
        // addErrorLog(errorInfo)
        return Promise.reject(error);
      }
    );
  }
  request(options) {
    const instance = axios.create();
    options = Object.assign(this.getInsideConfig(), options);
    this.interceptors(instance, options);
    // return instance(options);
    return this.returnInstance(instance, options);
  }
  handleErrorInfo(res = {}) {
    const { status, message } = res.data || {};
    if (status === 1002) {
      ElementUI.Message.error("登录已过期，请重新登录");
      ListenEvent.$emit("goLogin");
      // confirmDialog();
    } else {
      console.error("请求出错", res);
      ElementUI.Message.error(message);
    }
  }
  returnInstance(instance, options) {
    if (options.loading) {
      let loadingInstance = Loading.service(options.loading);
      return new Promise((resolve, reject) => {
        instance(options).then(
          (res) => {
            loadingInstance.close();
            resolve(res);
          },
          (err) => {
            loadingInstance.close();
            reject(err);
            this.handleErrorInfo(err);
          }
        );
      });
    } else {
      return new Promise((resolve, reject) => {
        instance(options).then(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
            this.handleErrorInfo(err);
          }
        );
      });
    }
  }
}

export default HttpRequest;
